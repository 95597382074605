.header{
  position: relative;
  &-content {
    position: fixed;
    text-align: right;
    top:16px;
    right: 21px;
    font-size: 2rem;
    transform: translateX(100%);
    opacity: 0;
    animation: .4s slideToLeft forwards;
    animation-delay: .8s;
    color: white;
    h1 {
      font-size: 3rem;
      @media (min-width: 769px)  { font-size: 2.5rem; }
      @media (min-width: 1200px) { font-size: 3.75rem; }
    }
    @media (min-width: 769px)  { font-size: 1rem; }
    /*@media (min-width: 1200px) {
      top:40%;
      right:10%;
    }*/
    z-index: 900;
  }
}

@keyframes slideToLeft {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}