//@import "bootstrap/_bootstrap.scss";

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap&subset=latin-ext');
@import 'reset';
@import "variables";

body {
  font-family: 'Montserrat', sans-serif;
  background-color: rgb(4, 4, 6);
}
p,h1,div {
  line-height: 1;
}
a {
  transition: $transition;
}
img {
  max-width:100%;
}
header {
  position: relative;
}

.invisible {
  opacity: 0;
  transition:0s;
}