.filmography {
  background-image: url('/img/1920/11.jpg');
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  display: flex;
  &:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: -50%;
    right: 0;
    background: rgba(0,0,0,0.85);
    z-index: 1;
  }
  &-container {
    position: relative;
    z-index: 150;
    color: white;
    //width: 100%;
    //max-width: 1170px;
    padding: 6rem 15px 0;
    margin: 0 auto;
  }
  &-row {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    @media (min-width: 769px) {
      margin-bottom: 4rem;
    }
    @media (min-width: 1200px) {
      margin-bottom: 5rem;
    }
    //justify-content: center;
    > *:nth-child(2) {
      margin: 0 2rem;
    }
    h2 {
      font-size: 1.5rem;
      @media (min-width: 769px) {
        font-size: 2.25rem;
      }
      @media (min-width: 1200px) {
        font-size: 3rem;
      }
    }
  }
  &-as-a {
    font-size: 0.75rem;
    color: rgba(white,0.5);
    @media (min-width: 769px) {
      font-size: 0.85rem;
    }
    @media (min-width: 1200px) {
      font-size: 1rem;
    }
  }
  &-icon {
    width: 30px;
    font-size: 2.5rem;
    @media (min-width: 769px) {
      font-size: 3.5rem;
      width: 75px;
    }
    @media (min-width: 1200px) {
      font-size: 5rem;
      width: 100px;
    }
  }
  &-year {
    width:40px;
    font-size: 1.5rem;
    color: rgba(white,0.25);
    text-align:center;
    &.small {
      width:40px;
      font-size: 0.75rem;
      text-align: left;
      position: relative;
      div {
        text-align: right;
      }
    }
    @media (min-width: 769px) {
      width:100px;
      font-size: 3rem;
      &.small {
        width:100px;
        font-size: 1.5rem;
        &:after {
          content: '';
          position: absolute;
          left: 25%;
          width: 50%;
          top: 46%;
          height: 1px;
          background: rgba(255, 255, 255, 0.15);
          transform: rotate(-41deg);
        }
      }
    }
    @media (min-width: 1200px) {
      width:200px;
      font-size: 4rem;
      &.small {
        width:190px;
        font-size: 2.5rem;
        padding-left: 10px;
        div {
          padding-right: 10px;
        }

        &:after {
          height: 2px;
        }
      }
    }
  }
}