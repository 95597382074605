@import './../../scss/variables.scss';

nav#main-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 550px;
  max-width: 100%;
  background: rgba(0,0,0,0.9);
  transform: translateX(-100%);
  transition: $transition;
  z-index: 9500;
  &.active {
    transform: translateX(0);
  }
  ul {
    color: white;
    font-size: 1.75rem;
    list-style: none;
    margin-top: 7rem;
    padding-left: 2rem;
    @media (min-width: 769px) {
      font-size: 2.25rem;
    }
    @media (min-width: 1200px) {
      font-size: 2.5rem;
    }
    li:not(:last-child) {
      margin-bottom: 2rem;
      @media (min-width: 1200px) {
        margin-bottom: 3.5rem;
      }
    }
    a {
      color: white;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
  }
}