
$hamburger-padding-x           : 15px;
$hamburger-padding-y           : 15px;
$hamburger-layer-width         : 40px;
$hamburger-layer-height        : 4px;
$hamburger-layer-spacing       : 6px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 1;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-hover-use-filter    : false;
$hamburger-hover-filter        : opacity(50%);
$hamburger-active-hover-filter : $hamburger-hover-filter;

@import "./../../../scss/variables";
@import "./../../../../node_modules/hamburgers/_sass/hamburgers/base";

.active .hamburger-arrow {
  .hamburger-inner {
    &::before {
      transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
    &::after {
      transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
    }
  }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: white;
}

.hamburger-inner {
  &, &:before,&:after {
    box-shadow: 0 0 4px rgba(0,0,0,0.4);
  }
}

nav:not(.active) .hamburger-filmography.scrolled {
  background-color: rgba(255,255,255,0.9);
  .hamburger-inner {
    &, &:before,&:after {
      box-shadow: none;
      background-color: black !important;
    }
  }
}

button.hamburger {
  transition: $transition;
  position: absolute;
  top: 8px;
  right: 0;
  transform: translateX(100%);
  outline: 0;
  .hamburger-inner {
    &, &:before,&:after {
      transition: background-color $transition;
      will-change: background-color;
    }
  }
  .active & {
    transform: translateX(0);
    .hamburger-inner {
      &, &:before,&:after {
        background-color: white !important;
      }
    }
    &.bg-white-transparent {
      background: transparent;
    }
  }
  .white & {
    .hamburger-inner {
      &, &:before,&:after {
        background-color: white;
      }
    }
  }
}
