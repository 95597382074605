.anim {
  position: relative;
  &-rotate {
    &:not(.loading) {
      transition: 12s;
      will-change: transform;
      transition-property: transform;
      transform: scale(1.15) rotate(2.5deg);
      -moz-transform: scale(1.15) rotate(2.5deg);
    }
    &.in-view {
      transform: scale(1) !important;
      -moz-transform: scale(1.15) rotate(0deg) !important;
    }
  }
  &-blur {
    &:not(.loading) {
      filter: blur(4px);
      transition: 2s;
      will-change: filter;
      transition-property: filter;
    }
    &.in-view {
      filter: unset;
    }
  }
}