.contact {
  background-image: url('/img/1920/H&T2.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  color: white;
  &:after {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: -50%;
    right: 0;
    background: rgba(0,0,0,0.85);
    z-index: 1;
  }
  &-container {
    position: relative;
    z-index: 150;
    color: white;
    padding: 6rem 15px 0;
    margin: 0 auto;
  }
  h1 {
    font-size:3.5rem;
    margin-bottom: 2rem;
    @media (min-width: 769px) {
      font-size:5rem;
    }
  }
  a {
    color: white;
  }
  &-content {
    padding-top: 8rem;
    position: relative;
    z-index: 150;
    text-align: center;
    font-size: 1.5rem;
    div {
      line-height: 1.5;
      margin-bottom: 2em;
    }
  }
}