@import './../../scss/variables';

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  animation: loaderFadeIn 1s ease-in forwards;
  &-spinner {
      border: 6px solid rgba(dimgray,0.5); /* Light grey */
      border-top: 6px solid rgba($primary,0.4); /* Blue */
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 0.8s linear infinite;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes loaderFadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}