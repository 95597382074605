figure {
  overflow: hidden;
  position: relative;
  min-height: 100px;
}
.photo {
  &-container {
    line-height: 0;
  }
  &-figure {
    margin: -20px 0;
  }
  &-wrap {
    line-height: 0;
  }
}