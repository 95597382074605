@import './../../../scss/variables.scss';

.social {
  margin-top: 3rem;
  padding-left: 2rem;
  font-size: 3rem;
  a {
    color: white;
    &:hover {
      color: $primary;
    }
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}