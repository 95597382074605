
@import "./../../scss/variables";

.title {
  position: fixed;
  text-align: right;
  top: 8px;
  right: 0;
  font-size: 1rem;
  //transform: translateX(100%);
  //opacity: 0;
  /*animation: .4s slideToLeft forwards;
  animation-delay: .8s;*/
  color: white;
  padding: 0 16px;
  height: 57px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  transition: opacity $transition;
  will-change: opacity;
  text-shadow: 0 0 4px rgba(0,0,0,0.4);
  h1 {
    text-transform: uppercase;
    font-size: 1.5em;
    @media (min-width: 769px)  { font-size: 2rem; }
    @media (min-width: 1200px) { font-size: 2.25rem; }
  }
  @media (min-width: 769px)  {
    font-size: 1.25rem;
    padding: 5px 20px 0 0;
  }
  /*@media (min-width: 1200px) {
    top:40%;
    right:10%;
  }*/
  z-index: 900;

  @media (max-width: 769px) {
    &.scrolled:not(.title-homepage) {
      opacity: 0;
      /*color: black;
      background: white;*/
    }
  }
}